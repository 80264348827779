<template>
    <b-modal
      v-model="show"
      :title="$t('modalTitle')"
      header-bg-variant="primary"
      header-text-variant="light"
      :cancel-title="$t('cancelTitle')"
      :ok-title="$t('okTitle')"
      size="lg"
      @ok="onOk"
      @cancel="onCancel"
    >
        <div v-if="parameters.length > 0">
            <iz-evaluator-parameters
                :evaluated-parameters="parameters"
                :two-columns="true"
            >
                <template #heading>
                    <p class="mb-4">
                        <strong>
                            {{$t('investmentCategoriesResult')}}
                        </strong>
                    </p>
                </template>
            </iz-evaluator-parameters>
        </div>
        <div
            v-else
            class="alert alert-danger"
        >
            {{$t('noCategoriesMessage')}}
        </div>
    </b-modal>
</template>

<script>
import IzEvaluatorParameters from './Sidebar/IzEvaluatorParameters'

export default {
    name: 'InvestmentCategoriesModal',
    components: {
        IzEvaluatorParameters
    },
    props: {
        parameters: {
            type: Array,
            required: false,
            default () {
                return []
            }
        },
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', !!value)
            }
        }
    },
    methods: {
        onOk () {
            this.$emit('ok')
        },
        onCancel () {
            this.$emit('cancel')
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "investmentCategoriesResult": "Na základě vašich odpovědí vám byly určeny tyto investiční parametry:",
        "noProfilesMessage": "Na základě vašich odpovědí vám nelze přidělit žádný investiční parametr.",
        "modalTitle": "Potvrdit výběr parametrů",
        "cancelTitle": "Zpět k dokumentu",
        "okTitle": "Potvrdit a pokračovat"
    },
    "sk": {
        "investmentCategoriesResult": "Na základe vašich odpovedí vám boli určené tieto investičné parametre:",
        "noProfilesMessage": "Na základe vašich odpovedí vám nemožno prideliť žiadny investičný parameter.",
        "modalTitle": "Potvrdiť výber parametrov",
        "cancelTitle": "Späť k dokumentu",
        "okTitle": "Potvrdiť a pokračovať"
    },
    "en": {
        "investmentCategoriesResult": "Based on your answers, you have been assigned the following investment parameters:",
        "noProfilesMessage": "You cannot be assigned an investment parameter based on your answers.",
        "modalTitle": "Confirm parameter selection",
        "cancelTitle": "Back to Document",
        "okTitle": "Confirm and continue"
    },
    "hr": {
        "investmentCategoriesResult": "Na temelju vaših odgovora dodijeljeni su vam sljedeći parametri ulaganja:",
        "noProfilesMessage": "Ne može vam se dodijeliti investicijski parametar na temelju vaših odgovora.",
        "modalTitle": "Potvrdite odabir parametra",
        "cancelTitle": "Natrag na dokument",
        "okTitle": "Potvrdi i nastavi"
    }
}
</i18n>
