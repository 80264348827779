<template>
    <b-form
        @submit="onSubmit"
        novalidate
        :id="migratedSchema.formId"
    >
        <portal
            v-for="element in migratedSchema.formElements"
            :key="element.elementId"
            :to="element.portalId"
        >
            <div>
                <component
                    :is="getComponentName(element)"
                    :schema="element"
                    v-model="form[element.elementId]"
                    :validate="element.validationRules.length > 0"
                    :valid="!$v.form[element.elementId].$invalid"
                    :validation-message="getValidationMessage(element.elementId)"
                    v-show="isElementVisible(element.visibilityRules, element.defaultVisible)"
                    :options="(['select', 'radio', 'imageRadio', 'checkboxGroup', 'investmentCategoriesSelector'].indexOf(element.elementType) > -1) ? element.elementOptions : undefined"
                    :checked-disabled-options="('investmentCategoriesSelector' === element.elementType) ? mandatoryInvestmentCategories : undefined"
                    :prompt-text="(element.elementType === 'select') ? element.selectPrompt : undefined"
                    :stacked="(element.vertical !== undefined) ? element.vertical : undefined"
                    :custom-classes="((element.customClasses !== undefined) ? element.customClasses : [])"
                    :form-values="form"
                />
            </div>
        </portal>
        <slot></slot>
        <div class="text-center mt-5">
            <hr/>
            <div v-if="showSaving">
                <div class="btn btn-lg btn-secodary">
                    {{$t('saving')}}
                </div>
            </div>
            <div v-else>
                <div>
                    <b-btn
                        v-if="showSave"
                        type="submit"
                        variant="primary"
                        size="lg"
                        :disabled="$v.form.$invalid"
                        class="mb-2"
                    >
                        {{$t('saveDocument')}}
                    </b-btn>

                    <b-btn
                        v-if="migratedSchema.hasOwnProperty('cancelText') && showCancel"
                        @click.prevent="$emit('cancel')"
                        variant="secondary"
                        size="lg"
                        class="ml-4 mb-2"
                    >
                        {{$t('storno')}}
                    </b-btn>
                </div>

                <div class="container">
                    <div v-if="previousEnabled || nextEnabled" class="d-flex justify-content-between flex-wrap align-items-center align-content-center">
                        <div class="text-center text-sm-left">
                            <b-btn
                                v-if="previousEnabled"
                                variant="light"
                                @click.prevent="$emit('previous')"
                            >
                                <b-icon icon="chevron-double-left" /> {{$t('previousDocument')}}
                            </b-btn>
                        </div>

                        <div class="text-center text-sm-right">
                            <b-btn
                                v-if="nextEnabled"
                                variant="light"
                                @click.prevent="$emit('next')"
                            >
                                {{$t('nextDocument')}} <b-icon icon="chevron-double-right" />
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-form>
</template>

<script>
import { SchemaForm, SchemaMigration } from '@cyrrus/dochub-schema-form'
import { schemas } from '@cyrrus/dochub-schema-form/src/components/schema/index.js'

export default {
    name: 'PortalSchemaForm',
    extends: SchemaForm,
    props: {
        schema: {
            type: Object,
            required: true,
            validator: function (value) {
                try {
                    const schema = new SchemaMigration(JSON.parse(JSON.stringify(value))).getMigratedSchema()
                    const validationResult = !((!schemas.formValidation(schema)) === true)
                    if (!validationResult) {
                        console.warn('Validation errors for value: ' + JSON.stringify(value))
                        console.warn('Errors:')
                        for (const error of schemas.formValidation.errors) {
                            console.warn(`${error.keyword} ${error.message} at ${error.dataPath}`)
                        }
                    }
                    return validationResult
                } catch (error) {
                    console.error(error)
                }
                return false
            }
        },
        showSave: {
            type: Boolean,
            required: false,
            default: true
        },
        showCancel: {
            type: Boolean,
            required: false,
            default: true
        },
        showSaving: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Object,
            required: false,
            default () {
                return {}
            }
        },
        previousEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        nextEnabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        onSubmit (event) {
            event.preventDefault()
            if (!this.$v.form.$invalid && !this.showSaving) {
                this.$emit('submit', JSON.parse(this.formString))
            }
        }
    },
    watch: {
        formString (value) {
            if (!this.showSaving) {
                if (!this.$v.form.$invalid) {
                    this.$emit('change', value)
                }
                this.$emit('data-changed', value)
                this.$emit('input', JSON.parse(value))
            }
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "saving": "Ukládání..",
        "saveDocument": "Uložit dokument",
        "storno": "Storno",
        "previousDocument": "Předchozí",
        "nextDocument": "Další"
    },
    "sk": {
        "saving": "Ukladanie..",
        "saveDocument": "Uložiť dokument",
        "storno": "Storno",
        "previousDocument": "Predchádzajúci",
        "nextDocument": "Ďalší"
    },
    "en": {
        "saving": "Saving..",
        "saveDocument": "Save document",
        "cancel": "Cancel",
        "previousDocument": "Previous",
        "nextDocument": "Next"
    },
    "hr": {
        "saving": "Spremanje..",
        "saveDocument": "Spremi dokument",
        "cancel": "Otkaži",
        "previousDocument": "Prethodni",
        "nextDocument": "Sljedeći"
    }
}
</i18n>
