<template>
    <div>
        <div class="container">
            <h1 class="mb-3">{{$t('landing.heading')}}</h1>
            <div class="mb-4">
                <b-button
                    class="text-primary border mr-3"
                    variant="light"
                    size="sm"
                    @click.prevent="onShowMigratedDataModal"
                >
                    <b-icon icon="file-earmark-plus" />
                    {{$t('migratedData.modal.prefill')}}
                </b-button>
                <b-button
                    class="text-danger border"
                    variant="light"
                    size="sm"
                    @click.prevent="onResetDocument"
                >
                    <b-icon icon="trash" />
                    {{$t('migratedData.modal.reset')}}
                </b-button>
            </div>
        </div>

        <div v-if="!session && loading" class="container">
            <div class="bg-white p-5 shadow cyrrus-rounded">
                <loader-overlay :loading="loading">
                    <div class="py-5"></div>
                </loader-overlay>
            </div>
        </div>

        <div v-if="session && !session.isSigned" class="d-flex justify-content-center align-items-center">
            <div class="bg-white px-0 px-sm-4 py-5 shadow cyrrus-rounded w-100 mw-1188">
                <div class="overflow-hidden">
                    <transition
                        enter-active-class="animated fadeInLeft"
                        leave-active-class="animated fadeOutRight"
                        mode="out-in"
                    >
                        <div
                            v-if="documentRendererActive && !loading"
                            key="renderer"
                        >
                            <document-renderer
                                :document="document"
                                :default-data="documentDataObject"
                                :client-data="client"
                                :company-data="company"
                                @submit="onDocumentSubmit"
                                :show-saving="saving"
                                v-model="documentDataObject"
                                :previous-enabled="false"
                                :next-enabled="false"
                                :data-saving="dataSaving"
                                :current-investment-parameters="clientInvestmentParameters"
                                :current-investment-profiles="[]"
                                :mandatory-investment-categories="documentMandatoryInvestmentCategories"
                                :identity-id="identityId"
                            >
                                <template #header>
                                    <div
                                        v-if="!clientMeetsMandatoryCategoriesCriteria"
                                        class="container text-center"
                                    >
                                        <h1>{{ activeDocument.title }}</h1>
                                        <hr>
                                    </div>
                                </template>
                                <template
                                    v-if="!clientMeetsMandatoryCategoriesCriteria"
                                    #content
                                >
                                    <div class="container">
                                        <missing-investment-categories-alert
                                            :missing-investment-categories="missingInvestmentCategories"
                                        />
                                    </div>
                                </template>
                            </document-renderer>
                        </div>
                        <div
                            v-else
                            key="dummy"
                        ></div>
                    </transition>
                </div>
            </div>
        </div>

        <found-migrated-data-modal
            v-model="isMigratedDataModalVisible"
            :migrated-data="migratedData || {}"
            @ok="onApplyMigratedData"
            @close="onCloseMigratedDataModal"
        />
        <investment-categories-modal
            v-if="showParameters && parameters !== null && session"
            v-model="showParameters"
            :parameters="parameters"
            @ok="onModalConfirmed"
            @cancel="onModalCanceled"
        />
    </div>
</template>

<script>
import { ClientData } from '@cyrrus/clientdatastructure'
import DocumentRenderer from './Components/DocumentRenderer.vue'
import FoundMigratedDataModal from './Components/FoundMigrationDataModal.vue'
import InvestmentCategoriesModal from './Components/InvestmentCategoriesModal.vue'
import MissingInvestmentCategoriesAlert from './Components/MissingInvestmentCategoriesAlert.vue'
import LoaderOverlay from '../../../../Components/LoaderOverlay.vue'

export default {
    name: 'Index',
    components: {
        DocumentRenderer,
        InvestmentCategoriesModal,
        MissingInvestmentCategoriesAlert,
        LoaderOverlay,
        FoundMigratedDataModal
    },
    data () {
        return {
            session: null,
            documentRendererActive: false,
            documentLoading: true,
            company: null,
            client: null,
            loading: true,
            saving: false,
            showParameters: false,
            parameters: null,
            investmentCategoriesEnum: [],
            document: null,
            documentType: null,
            documentSaved: false,
            documentData: JSON.stringify({}),
            dataSaving: false,
            isMigratedDataModalVisible: false,
            migratedData: null
        }
    },
    computed: {
        identityId () {
            return this.session && this.session.identityId ? this.session.identityId : ''
        },
        missingInvestmentCategories () {
            const categories = this.clientInvestmentCategories.map(item => item.name)
            return this.documentMandatoryInvestmentCategories.filter(item => !categories.includes(item))
        },
        documentMandatoryInvestmentCategories () {
            return this.documentType ? (this.documentType.mandatoryInvestmentCategories || []) : []
        },
        clientInvestmentCategories () {
            return this.clientInvestmentParameters.filter(item => item.isInvestmentCategory)
        },
        clientMeetsMandatoryCategoriesCriteria () {
            if (this.documentMandatoryInvestmentCategories.length === 0) {
                return true
            }
            const clientCategories = this.clientInvestmentCategories.map(item => item.name)
            return this.documentMandatoryInvestmentCategories.every(item => clientCategories.includes(item))
        },
        clientId () {
            return (this.client && this.client.client && this.client.client.dataOriginId) ? this.client.client.dataOriginId : null
        },
        clientInvestmentParameters () {
            return (this.client.client.investmentParameters || [])
        },
        documentDataObject: {
            get () {
                return JSON.parse(this.documentData)
            },
            set (newValue) {
                this.documentData = JSON.stringify(newValue)
            }
        }
    },
    watch: {
        documentData (newValue, previousValue) {
            if (newValue !== previousValue) {
                this.dataSaving = true
                const previousData = JSON.stringify(JSON.parse(previousValue))
                this.$api.sessions.saveDocumentData(newValue)
                    .catch((error) => {
                        console.error(error)
                        this.$nextTick(() => {
                            this.documentData = previousData
                        })
                    }).finally(() => {
                        this.$nextTick(() => {
                            this.dataSaving = false
                        })
                    })
            }
        }
    },
    async mounted () {
        this.documentRendererActive = false
        this.loading = true
        await this.loadInvestmentCategoriesEnum()
        await this.loadSession()
        await this.loadMigratedData()
        let langKey = `${this.session.language}`.substring(0, 2).toLowerCase()
        if (langKey === 'cs') {
            langKey = 'cz'
        }
        if (this.$i18n.locale !== langKey && ['cz', 'en', 'sk', 'hr'].includes(langKey)) {
            this.$i18n.locale = langKey
            this.$store.commit('setLang', langKey)
        }
        this.$api.sessions.toggleSigneable(false).catch((error) => { console.error(error) })
        this.$nextTick(() => {
            if (JSON.parse(JSON.stringify(this.migratedData ?? {}))?.migratedData) {
                this.isMigratedDataModalVisible = true
            }

            this.loading = false
            this.documentRendererActive = true
        })
    },
    methods: {
        async loadInvestmentCategoriesEnum () {
            try {
                this.investmentCategoriesEnum = await this.$api.enums.read('investment-categories')
            } catch (error) {
                console.error(error.message)
            }
        },
        async loadSession () {
            try {
                const response = await this.$api.sessions.read('current')
                const { clientData, document, documentType, company, documentData, ...session } = response.data
                if (session && session.isSigned) {
                    window.location = '/redir'
                }
                this.client = new ClientData(clientData)
                this.document = document
                this.documentType = documentType
                this.company = company
                this.session = session
                this.documentData = documentData
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('fillout.sessionLoadError'))
            }
        },
        onDocumentCancel () {
            // @todo
        },
        async onDocumentSubmit () {
            const response = await this.$api.sessions.evaluateInvestmentParameters()
            if (response.status !== 204) {
                this.parameters = (response.data.parameters || [])
                if (!response.data.hasProfiles) {
                    this.showParameters = true
                }
            } else {
                await this.saveDocumentData(this.documentData)
            }
        },
        async saveDocumentData (documentData) {
            this.saving = true
            this.$api.sessions.submitDocument(documentData)
                .then(() => {
                    this.saving = false
                    this.onModalCanceled()
                    this.$nextTick(() => {
                        this.$router.push({ name: 'Signature' })
                    })
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('fillout.dataSaveError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.saving = false
                    })
                })
        },
        onModalConfirmed () {
            this.showParameters = false
            this.saveDocumentData(this.documentData)
        },
        onModalCanceled () {
            this.showParameters = false
            this.parameters = null
        },
        async loadMigratedData () {
            try {
                this.migratedData = (await this.$api.sessions.getMigratedDataForDocument()).data
            } catch (error) {
                console.error(error)
            }
        },
        onResetDocument () {
            this.$swal({
                title: this.$t('migratedData.swal.title'),
                text: this.$t('migratedData.swal.message'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.$t('migratedData.swal.cancel'),
                confirmButtonText: this.$t('migratedData.swal.confirm'),
                buttonsStyling: false,
                cancelButtonClass: 'btn btn-danger btn-sm ml-4',
                confirmButtonClass: 'btn btn-primary btn-sm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true
                    this.documentDataObject = {}
                    this.$nextTick(() => {
                        this.loading = false
                    })
                }
            }).catch(() => {
                // nothing
            })
        },
        onApplyMigratedData () {
            this.loading = true
            this.documentDataObject = this.migratedData.migratedData
            this.$nextTick(() => {
                this.loading = false
                this.isMigratedDataModalVisible = false
            })
        },
        onShowMigratedDataModal () {
            if (this.migratedData.migratedData) {
                this.isMigratedDataModalVisible = true
            }
        },
        onCloseMigratedDataModal () {
            this.isMigratedDataModalVisible = false
        }
    }
}
</script>
