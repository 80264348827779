<template>
    <div
        v-if="isOk"
    >
        <div
            class="document-root pb-4"
        >
            <slot name="header">
                <div>
                    <div v-if="isSaved" class="text-right container">
                        <b-badge variant="success" pill><b-icon icon="check"/> {{$t('fillout.documentSavedBadge')}}</b-badge>
                    </div>
                    <simplified-document-header
                        :client-data="clientData"
                        :company-data="companyData"
                        :document-title="document.title"
                        :is-ssr="false"
                        :locale="locale"
                        :identity-id="identityId"
                        :disabled-parts="companyData.disabledHeaderParts || []"
                    />
                </div>
            </slot>
            <slot name="content">
                <portal-schema-form
                    :schema="schema"
                    :default-data="defaultData"
                    @submit="onSubmit"
                    v-model="model"
                    @change="onChange"
                    @cancel="onCancel"
                    @data-changed="onDataChanged"
                    :show-save="showSave"
                    :show-cancel="false"
                    :show-saving="showSaving"
                    :investment-profiles="investmentProfiles"
                    :investment-parameters="investmentParameters"
                    :mandatory-investment-categories="mandatoryInvestmentCategories"
                    :next-enabled="nextEnabled"
                    :previous-enabled="previousEnabled"
                    @previous="$emit('previous')"
                    @next="$emit('next')"
                >
                    <dh-root
                        :element="rootElement"
                    />
                </portal-schema-form>
            </slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
import { ElementTree, ROOT_ID } from '@cyrrus/dochub-element-tree'
import PortalSchemaForm from './PortalSchemaForm.vue'
import { SimplifiedDocumentHeader } from '@cyrrus/dochub-document-header'
import { ClientData } from '@cyrrus/clientdatastructure'

export default {
    name: 'DocumentRenderer',
    components: {
        SimplifiedDocumentHeader,
        PortalSchemaForm
    },
    props: {
        showSave: {
            type: Boolean,
            required: false,
            default: true
        },
        showSaving: {
            type: Boolean,
            required: false,
            default: true
        },
        showCancel: {
            type: Boolean,
            required: false,
            default: true
        },
        document: {
            type: Object,
            required: true
        },
        defaultData: {
            type: Object,
            required: false
        },
        clientData: {
            type: Object,
            required: true
        },
        companyData: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: false,
            default () {
                return {}
            }
        },
        previousEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        nextEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isSaved: {
            type: Boolean,
            required: false,
            default: false
        },
        mandatoryInvestmentCategories: {
            type: Array,
            required: false,
            default: () => []
        },
        currentInvestmentParameters: {
            type: Array,
            required: false,
            default: () => []
        },
        currentInvestmentProfiles: {
            type: Array,
            required: false,
            default: () => []
        },
        identityId: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        locale () {
            switch (this.$store.state.lang) {
                case 'sk':
                    return 'sk_SK'
                case 'en':
                    return 'en_US'
                case 'hr':
                    return 'hr_HR'
            }
            return 'cs_CZ'
        },
        clientInstance () {
            return (this.clientData instanceof ClientData) ? this.clientData : new ClientData(this.clientData)
        },
        clientInvestmentProfiles () {
            const list = []
            const newest = {}
            for (const profile of this.clientInstance.client.investmentProfiles) {
                if (!newest.hasOwnProperty(profile.uid) || (newest[profile.uid].added < profile.added)) {
                    newest[profile.uid] = profile
                }
            }
            for (const uid in newest) {
                list.push(newest[uid])
            }
            return list
        },
        investmentProfiles () {
            const list = []
            const newest = {}
            const profiles = (Array.isArray(this.currentInvestmentProfiles) && this.currentInvestmentProfiles.length > 0) ? this.currentInvestmentProfiles : this.clientInstance.client.investmentProfiles
            for (const profile of profiles) {
                if (!newest.hasOwnProperty(profile.uid) || (newest[profile.uid].added < profile.added)) {
                    newest[profile.uid] = profile
                }
            }
            for (const uid in newest) {
                list.push(newest[uid])
            }
            return list
        },
        investmentProfilesList () {
            return [...new Set(this.investmentProfiles.map(item => item.uid).filter(item => item))]
        },
        investmentParameters () {
            return (((Array.isArray(this.currentInvestmentParameters) && this.currentInvestmentParameters.length > 0)
                ? this.currentInvestmentParameters
                : this.clientInstance.client.investmentParameters || []
            ) || [])
        },
        isOk () {
            try {
                /* eslint-disable-next-line no-unused-vars */
                const tree = new ElementTree(JSON.parse(this.document.elements_schema_json))
                return true
            } catch (error) {
                return false
            }
        },
        rootElement () {
            if (this.isOk) {
                const tree = new ElementTree(JSON.parse(this.document.elements_schema_json))
                return tree.getElement(ROOT_ID)
            }
            return null
        },
        schema () {
            const schema = {
                formId: '' + this.document._id,
                formElements: [],
                submitText: this.$t('fillout.submitAndContinue'),
                cancelText: this.$t('fillout.cancelAndBack')
            }
            if (this.isOk) {
                const elementsSchema = JSON.parse(this.document.elements_schema_json)
                for (const index in elementsSchema) {
                    const item = elementsSchema[index]
                    if (item.type === 'input') {
                        schema.formElements.push({ ...item.elementData.schemaProps, portalId: item.id })
                    }
                }
            }
            return schema
        }
    },
    mounted () {
        if (!this.isOk) {
            this.$emit('render-error')
        }
    },
    methods: {
        onSubmit (data) {
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        },
        onChange (data) {
            this.$emit('change', data)
        },
        onDataChanged (value) {
            this.$emit('data-changed', value)
        },
        onSetFilloutVar (data) {
            this.$emit('set-fillout-var', data)
        }
    }
}
</script>
