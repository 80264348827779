<template>
    <div class="mt-4">
        <slot name="heading">
            <p>
                <strong>{{$t('heading')}}</strong>
            </p>
        </slot>

        <div
            v-if="parameters.length > 0"
        >
            <div class="list-group" v-if="twoColumns === false">
                <div
                    v-for="item in parameters"
                    :key="`parameter-${item.uid}`"
                    class="list-group-item"
                >
                    <div class="d-flex justify-content-between align-content-center align-items-center">
                        <div class="flex-grow-1">
                            <div>
                                <strong>{{item.title}}</strong>
                            </div>
                            <small>{{$t('parameterValue')}}: <strong>{{item.textValue}}</strong></small>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 col-md-6">
                    <div
                        v-for="item in parameters.filter((item, index) => 1 + index <= Math.floor(parameters.length / 2))"
                        :key="`parameter-${item.uid}`"
                        class="list-group-item"
                    >
                        <div class="d-flex justify-content-between align-content-center align-items-center">
                            <div class="flex-grow-1">
                                <div>
                                    <strong>{{item.title}}</strong>
                                </div>
                                <small>{{$t('parameterValue')}}: <strong>{{item.textValue}}</strong></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div
                        v-for="item in parameters.filter((item, index) => 1 + index > Math.floor(parameters.length / 2))"
                        :key="`parameter-${item.uid}`"
                        class="list-group-item"
                    >
                        <div class="d-flex justify-content-between align-content-center align-items-center">
                            <div class="flex-grow-1">
                                <div>
                                    <strong>{{item.title}}</strong>
                                </div>
                                <small>{{$t('parameterValue')}}: <strong>{{item.textValue}}</strong></small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p
            v-else
            class="alert alert-warning"
        >
            {{$t('noParameters')}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'IzEvaluatorParameters',
    props: {
        twoColumns: {
            type: Boolean,
            required: false,
            default: false
        },
        document: {
            type: Object,
            required: false,
            default: () => ({})
        },
        formData: {
            type: Object,
            required: false,
            default: () => ({})
        },
        evaluatedParameters: {
            required: false,
            default: () => null
        }
    },
    computed: {
        evaluatedParametersList () {
            if (!this.evaluatedParameters) {
                return []
            }
            return this.evaluatedParameters.map(item => {
                const parameter = {}
                for (const key in item.parameter) {
                    parameter[((key.startsWith('_')) ? key.slice(1) : key)] = item.parameter[key]
                }
                return {
                    ...item,
                    parameter
                }
            })
        },
        yesNoParameter () {
            return (xmlValue) => xmlValue === '1' ? this.$t('yes') : this.$t('no')
        },
        categoryTitlesMap () {
            return new Map([
                ['parInvCatBonds', this.$t('parInvCatBonds')],
                ['parInvCatCurrencyForwards', this.$t('parInvCatCurrencyForwards')],
                ['parInvCatDerivates', this.$t('parInvCatDerivates')],
                ['parInvCatETF', this.$t('parInvCatETF')],
                ['parInvCatFki', this.$t('parInvCatFki')],
                ['parInvCatFunds', this.$t('parInvCatFunds')],
                ['parInvCatLeveragedProducts', this.$t('parInvCatLeveragedProducts')],
                ['parInvCatStocks', this.$t('parInvCatStocks')]
            ])
        },
        lossAcceptanceMap () {
            return new Map([
                ['1', this.$t('lossAcceptanceMap1')],
                ['2', this.$t('lossAcceptanceMap2')],
                ['3', this.$t('lossAcceptanceMap3')],
                ['4', this.$t('lossAcceptanceMap4')]
            ])
        },
        investTargetMap () {
            return new Map([
                ['1', this.$t('investTargetMap1')],
                ['2', this.$t('investTargetMap2')],
                ['3', this.$t('investTargetMap3')],
                ['4', this.$t('investTargetMap4')]
            ])
        },
        knowledgeLevelMap () {
            return new Map([
                ['1', this.$t('knowledgeLevelMap1')],
                ['2', this.$t('knowledgeLevelMap2')],
                ['3', this.$t('knowledgeLevelMap3')]
            ])
        },
        parameters () {
            return [
                'parInvestmentKnowledge',
                'parInvCatBonds',
                'parInvCatStocks',
                'parInvCatETF',
                'parInvCatFunds',
                'parInvCatFki',
                'parInvCatDerivates',
                'parInvCatLeveragedProducts',
                'parInvCatCurrencyForwards',
                'parLossAcceptance',
                'parSri',
                'parInvestmentTarget',
                'parInvestmentAmount',
                'parPolitician',
                'parAMLProfile',
                'parEsgProfile',
                'parRiskCountryActivity'
            ].map(item => this.validatedParametersMap.get(item))
                .filter(item => item)
        },
        hasEvaluator () {
            return (
                (this.document) &&
                (this.document.evaluator !== null) &&
                (this.document.evaluator !== undefined) &&
                (this.$evaluator.getVersionsList().indexOf(this.document.evaluator) > -1)
            )
        },
        validatedParameters () {
            if (this.evaluatedParameters !== null) {
                return this.evaluatedParametersList
            }
            return this.hasEvaluator ? this.$evaluator.getVersion(this.document.evaluator).getValidatedParameters(this.formData) : []
        },
        validatedParametersMap () {
            return new Map(this.validatedParameters.map(item => {
                const xmlValue = item.valid ? item.parameter.xmlValidValue : item.parameter.xmlInvalidValue
                const uid = item.parameter.uid
                let textValue = null
                let title = null
                switch (item.parameter.uid) {
                    case 'parAMLProfile':
                        title = this.$t('parAMLProfile')
                        textValue = xmlValue
                        break
                    case 'parInvCatBonds':
                    case 'parInvCatCurrencyForwards':
                    case 'parInvCatDerivates':
                    case 'parInvCatETF':
                    case 'parInvCatFki':
                    case 'parInvCatFunds':
                    case 'parInvCatLeveragedProducts':
                    case 'parInvCatStocks':
                        title = this.categoryTitlesMap.get(item.parameter.uid)
                        textValue = this.yesNoParameter(xmlValue)
                        break
                    case 'parLossAcceptance':
                        title = this.$t('parLossAcceptance')
                        textValue = this.lossAcceptanceMap.get(`${xmlValue}`) || this.$t('undeterminable')
                        break
                    case 'parSri':
                        title = this.$t('parSri')
                        textValue = (xmlValue) ? `${this.$t('level')} ${xmlValue}` : this.$t('undeterminable')
                        break
                    case 'parEsgProfile':
                        title = this.$t('parEsgProfile')
                        textValue = (xmlValue) ? `${this.$t('level')} ${xmlValue}` : this.$t('undeterminable')
                        break
                    case 'parInvestmentAmount':
                        title = this.$t('parInvestmentAmount')
                        textValue = (xmlValue) ? `${xmlValue}` : this.$t('undeterminable')
                        break
                    case 'parInvestmentTarget':
                        title = this.$t('parInvestmentTarget')
                        textValue = this.investTargetMap.get(`${xmlValue}`) || this.$t('undeterminable')
                        break
                    case 'parRiskCountryActivity':
                        title = this.$t('parRiskCountryActivity')
                        textValue = this.yesNoParameter(xmlValue)
                        break
                    case 'parPolitician':
                        title = this.$t('parPolitician')
                        textValue = this.$t(xmlValue === 'SPECIAL_PARAMETER_SET_EMPTY' ? 'yes' : 'no')
                        break
                    case 'parInvestmentKnowledge':
                        title = this.$t('parInvestmentKnowledge')
                        textValue = this.knowledgeLevelMap.get(`${xmlValue}`) || this.$t('undeterminable')
                        break
                }
                return [uid, { uid, xmlValue, textValue, title }]
            }))
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "heading": "Výstup investičního dotazníku:",
        "parameterValue": "Hodnota parametru",
        "noParameters": "Nejsou vydefinovány žádné parametry k vyhodnocení.",
        "yes": "Ano",
        "no": "Ne",
        "parInvCatBonds": "Dluhopisy",
        "parInvCatCurrencyForwards": "Měnové forwardy",
        "parInvCatDerivates": "Investiční certifikáty",
        "parInvCatETF": "ETF",
        "parInvCatFki": "Fondy kval. inv.",
        "parInvCatFunds": "Fondy",
        "parInvCatLeveragedProducts": "Pákové produkty",
        "parInvCatStocks": "Akcie",
        "undeterminable": "nelze určit",
        "parLossAcceptance": "Akceptace ztráty",
        "lossAcceptanceMap1": "do 5%",
        "lossAcceptanceMap2": "5-40%",
        "lossAcceptanceMap3": "40-80%",
        "lossAcceptanceMap4": "80-100%",
        "parSri": "Risk tolerance",
        "level": "úroveň",
        "parPolitician": "Politický exponent",
        "parInvestmentAmount": "Plánovaná výše investice",
        "parInvestmentTarget": "Investiční cíle",
        "parInvestmentKnowledge": "Úroveň znalostí klienta",
        "investTargetMap1": "do 1 roku",
        "investTargetMap2": "1-3 roky",
        "investTargetMap3": "3-5 let",
        "investTargetMap4": "5 let a více",
        "knowledgeLevelMap1": "základní",
        "knowledgeLevelMap2": "informovaný",
        "knowledgeLevelMap3": "pokročilý",
        "parAMLProfile": "AML Profil",
        "parEsgProfile": "ESG Profil",
        "parRiskCountryActivity": "Aktivita v rizikových zemích"
    },
    "sk": {
        "heading": "Výstup investičného dotazníka:",
        "parameterValue": "Hodnota parametra",
        "noParameters": "Nie sú definované žiadne parametre na vyhodnotenie.",
        "yes": "Áno",
        "no": "Nie",
        "parInvCatBonds": "Dlhopisy",
        "parInvCatCurrencyForwards": "Menové forwardy",
        "parInvCatDerivates": "Investičné certifikáty",
        "parInvCatETF": "ETF",
        "parInvCatFki": "Fondy kval. inv.",
        "parInvCatFunds": "Fondy",
        "parInvCatLeveragedProducts": "Pákové produkty",
        "parInvCatStocks": "Akcie",
        "undeterminable": "nie je možné určiť",
        "parLossAcceptance": "Akceptácia straty",
        "lossAcceptanceMap1": "do 5%",
        "lossAcceptanceMap2": "5-40%",
        "lossAcceptanceMap3": "40-80%",
        "lossAcceptanceMap4": "80-100%",
        "parSri": "Risk tolerancie",
        "level": "úroveň",
        "parPolitician": "Politický exponent",
        "parInvestmentAmount": "Plánovaná výška investície",
        "parInvestmentTarget": "Investičné ciele",
        "parInvestmentKnowledge": "Úroveň znalostí klienta",
        "investTargetMap1": "do 1 roka",
        "investTargetMap2": "1-3 roky",
        "investTargetMap3": "3-5 rokov",
        "investTargetMap4": "5 rokov a viac",
        "knowledgeLevelMap1": "základné",
        "knowledgeLevelMap2": "informovaný",
        "knowledgeLevelMap3": "pokročilý",
        "parAMLProfile": "AML Profil",
        "parEsgProfile": "ESG Profil",
        "parRiskCountryActivity": "Aktivita v rizikových krajinách"
    },
    "en": {
        "heading": "Investment questionnaire output:",
        "parameterValue": "Parameter value",
        "noParameters": "No parameters are defined to be evaluated.",
        "yes": "Yes",
        "no": "No",
        "parInvCatBonds": "Bonds",
        "parInvCatCurrencyForwards": "Currency forwards",
        "parInvCatDerivates": "Investment certificates",
        "parInvCatETF": "ETF",
        "parInvCatFki": "Funds qual. inv.",
        "parInvCatFunds": "Funds",
        "parInvCatLeveragedProducts": "Leveraged products",
        "parInvCatStocks": "Stocks",
        "undeterminable": "can't be determined",
        "parLossAcceptance": "Loss Acceptance",
        "lossAcceptanceMap1": "up to 5%",
        "lossAcceptanceMap2": "5-40%",
        "lossAcceptanceMap3": "40-80%",
        "lossAcceptanceMap4": "80-100%",
        "parSri": "Risk tolerance",
        "level": "level",
        "parPolitician": "Politician exponent",
        "parInvestmentAmount": "Planned investment amount",
        "parInvestmentTarget": "Investment Targets",
        "parInvestmentKnowledge": "Client knowledge level",
        "investTargetMap1": "within 1 year",
        "investTargetMap2": "1-3 years",
        "investTargetMap3": "3-5 years",
        "investTargetMap4": "5 years and above",
        "knowledgeLevelMap1": "basic",
        "knowledgeLevelMap2": "informed",
        "knowledgeLevelMap3": "advanced",
        "parAMLProfile": "AML Profile",
        "parEsgProfile": "ESG Profile",
        "parRiskCountryActivity": "Activity in risk countries"
    },
    "hr": {
        "heading": "Izlaz investicijskog upitnika:",
        "parameterValue": "Vrijednost parametra",
        "noParameters": "Nijedan parametar nije definiran za procjenu.",
        "yes": "da",
        "no": "ne",
        "parInvCatBonds": "Obveznice",
        "parInvCatCurrencyForwards": "Forwards valuta",
        "parInvCatDerivates": "Investicijski certifikati",
        "parInvCatETF": "ETF",
        "parInvCatFki": "Kval. inv. sredstava",
        "parInvCatFunds": "Sredstva",
        "parInvCatLeveragedProducts": "Proizvodi s polugom",
        "parInvCatStocks": "Dionice",
        "undeterminable": "ne može se odrediti",
        "parLossAcceptance": "Prihvaćanje gubitka",
        "lossAcceptanceMap1": "do 5%",
        "lossAcceptanceMap2": "5-40%",
        "lossAcceptanceMap3": "40-80%",
        "lossAcceptanceMap4": "80-100%",
        "parSri": "Tolerancija rizika",
        "level": "razina",
        "parPolitician": "Politički eksponent",
        "parInvestmentAmount": "Planirani iznos ulaganja",
        "parInvestmentTarget": "Ciljevi ulaganja",
        "parInvestmentKnowledge": "Razina znanja klijenta",
        "investTargetMap1": "unutar 1 godine",
        "investTargetMap2": "1-3 godine",
        "investTargetMap3": "3-5 godina",
        "investTargetMap4": "5 godina i više",
        "knowledgeLevelMap1": "osnovno",
        "knowledgeLevelMap2": "informiran",
        "knowledgeLevelMap3": "napredno",
        "parAMLProfile": "AML profil",
        "parEsgProfile": "ESG profil",
        "parRiskCountryActivity": "Aktivnost u rizičnim zemljama"
    }
}
</i18n>
