<template>
    <b-modal
        v-model="show"
        size="lg"
        id="found-migrated-data-modal"
        header-border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="light"
        :title="$t('migratedData.modal.title', { date: sourceDocumentDateString })"
        footer-class="d-flex justify-content-start"
        hide-footer
    >
        <p>{{$t('migratedData.modal.message', { date: sourceDocumentDateString })}}</p>
        <div class="d-flex mt-4">
            <b-button
                class="mr-3"
                variant="primary"
                @click.prevent="onOk"
            >
                {{$t('migratedData.modal.confirm')}}
            </b-button>
            <b-button
                variant="primary"
                @click.prevent="onCancel"
            >
                {{$t('migratedData.modal.cancel')}}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'FoundMigratedDataModal',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        migratedData: {
            type: Object,
            required: true
        }
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        sourceDocumentDate () {
            return this.migratedData?.sourceDocumentDate ?? null
        },
        sourceDocumentDateString () {
            return this.sourceDocumentDate ? new Date(this.sourceDocumentDate).toLocaleDateString() : this.$t('migratedData.modal.unknownDate')
        }
    },
    methods: {
        onCancel () {
            this.$emit('close')
        },
        onOk () {
            this.$emit('ok')
        }
    }
}
</script>
