<template>
    <div class="alert alert-danger rounded-0 shadow">
        <p class="font-weight-bold mb-1">
            {{$t('missingCategoriesAlert.content')}}
        </p>
        <p class="font-weight-bold">
            {{$t('missingCategoriesAlert.missing')}}
            <span>
                {{ missingInvestmentCategoriesText }}
            </span>
        </p>
        <div class="mt-4">
            <b-btn
                v-if="investProfileSetterDocumentId !== null"
                class="mr-3"
                variant="primary"
                @click.prevent="onGoToInvestProfileSetter"
            >
                {{$t('missingCategoriesAlert.goBack')}}
            </b-btn>
            <b-btn
                variant="danger"
                @click.prevent="onDeleteDocument"
            >
                {{$t('missingCategoriesAlert.discard')}}
            </b-btn>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MissingInvestmentCategoriesAlert',
    props: {
        missingInvestmentCategories: {
            type: Array,
            required: true
        },
        investProfileSetterDocumentId: {
            required: true
        }
    },
    computed: {
        missingInvestmentCategoriesText () {
            return this.missingInvestmentCategories.map(item => this.$t(`missingCategoriesAlert.investmentCategories.${item}`)).join(', ')
        }
    },
    methods: {
        onDeleteDocument () {
            this.$emit('delete-document')
        },
        onGoToInvestProfileSetter () {
            this.$emit('go-to-invest-profile-setter-document')
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "missingCategoriesAlert": {
            "content": "Klient nemá vyhodnocení investičního dotazníku, které je vyžadováno pro tento dokument.",
            "missing": "Chybějící investiční kategorie:",
            "goBack": "Vrátit se k investičnímu dotazníku",
            "discard": "Odebrat dokument",
            "investmentCategories": {
                "bonds": "Dluhopisy",
                "stocks": "Akcie",
                "etf": "ETF",
                "funds": "Fondy",
                "fki": "FKI",
                "derivates": "Investiční certifikáty",
                "leveragedProducts": "Pákové produkty",
                "currencyForwards": "Měnové forwardy"
            }
        }
    },
    "sk": {
        "missingCategoriesAlert": {
            "content": "Klient nemá vyhodnotenie investičného dotazníka, ktoré je vyžadované pre tento dokument.",
            "missing": "Chýbajúca investičná kategória:",
            "goBack": "Vrátiť sa k investičnému dotazníku",
            "discard": "Odobrať dokument",
            "investmentCategories": {
                "bonds": "Dlhopisy",
                "stocks": "Akcie",
                "etf": "ETF",
                "funds": "Fondy",
                "fki": "FKI",
                "derivates": "Investičné certifikáty",
                "leveragedProducts": "Pákové produkty",
                "currencyForwards": "Menové forwardy"
            }
        }
    },
    "en": {
        "missingCategoriesAlert": {
            "content": "The client does not have the investment questionnaire evaluation required for this document.",
            "missing": "Missing investment category:",
            "goBack": "Go back to the investment questionnaire",
            "discard": "Remove document",
            "investmentCategories": {
                "bonds": "Bonds",
                "stocks": "Stocks",
                "etf": "ETF",
                "funds": "Funds",
                "fki": "FKI",
                "derivates": "Investment certificates",
                "leveragedProducts": "Leveraged Products",
                "currencyForwards": "Currency Forwards"
            }
        }
    },
    "hr": {
        "missingCategoriesAlert": {
            "content": "Klijent nema procjenu investicijskog upitnika potrebnog za ovaj dokument.",
            "missing": "Nedostaje kategorija ulaganja:",
            "goBack": "Povratak na upitnik o ulaganju",
            "discard": "Ukloni dokument",
            "investmentCategories": {
                "bonds": "Obveznice",
                "stocks": "Dionice",
                "etf": "ETF",
                "funds": "Sredstva",
                "fki": "FKI",
                "derivates": "Investicijski certifikati",
                "leveragedProducts": "Proizvodi s polugom",
                "currencyForwards": "Forwards u valuti"
            }
        }
    }
}
</i18n>
